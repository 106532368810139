import React, {useContext, useState} from "react";
import {Layout, Menu, Drawer, Button} from "antd";
import {Link} from "react-router-dom";
import {MenuOutlined } from "@ant-design/icons";
import styles from "./styles.module.css";
import {Auth} from "modules";

const { Header} = Layout;

const FrontHeader : React.FC = () => {

    const [isVisible, setVisible] = useState<boolean>(false);
    const {user}                  = useContext(Auth.Auth);

    const menuItem = (mode : "horizontal" | "vertical" | "inline") => (
        <Menu className={mode === "horizontal" ? styles.menu : ""} theme="light" mode={mode}>
            <Menu.Item key="1">
                <Link className={"front-header"} to={"/tentang"}>
                    <strong>Tentang</strong>
                </Link>
            </Menu.Item>
            <Menu.Item key="2">
                <a className={"front-header"} href={"https://unismuh.ac.id/"}>
                    <strong>Portal Kampus</strong>
                </a>
            </Menu.Item>
            <Menu.Item key="3">
                <Link className={"front-header"} to={"/kewirausahaan"}>
                    <strong>Inkubator Kewirausahaan</strong>
                </Link>
            </Menu.Item>
            <Menu.Item key="4">
                <a className={"front-header"} href={"http://tracerstudy.unismuh.ac.id"}>
                    <strong>Tracert Study</strong>
                </a>
            </Menu.Item>
            {
                mode === "inline" ? (
                    <Menu>
                        <Menu.Item key="5">
                            <Link className={"front-header"} to={"/pusat-karir/program-karir"}>
                                <strong>Program Karir</strong>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="6">
                            <Link className={"front-header"} to={"/pusat-karir/info-loker"}>
                                <strong>Info Loker</strong>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="7">
                            <Link className={"front-header"} to={"/pusat-karir/magang"}>
                                <strong>Magang</strong>
                            </Link>
                        </Menu.Item>
                    </Menu>
                ) : (
                    <Menu.SubMenu className={styles.submenu} style={{color : "black !important"}} key={"sub1"} title={"Pusat Karir"}>
                        <Menu.Item key="5">
                            <Link className={"front-header"} to={"/pusat-karir/program-karir"}>
                                <strong>Program Karir</strong>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="6">
                            <Link className={"front-header"} to={"/pusat-karir/info-loker"}>
                                <strong>Info Loker</strong>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="7">
                            <Link className={"front-header"} to={"/pusat-karir/magang"}>
                                <strong>Magang</strong>
                            </Link>
                        </Menu.Item>
                    </Menu.SubMenu>
                )
            }
            <Menu.Item key="9">
                <Link className={"front-header"} to={"/masuk"}>
                    <strong>{user.id ? "Dashboard" : "Masuk"}</strong>
                </Link>
            </Menu.Item>
        </Menu>
    )

    return (
        <>
            <Layout>

                <Drawer
                    title={null}
                    placement={"right"}
                    closable={false}
                    visible={isVisible}
                    onClose={() => setVisible(false)}
                >
                    { menuItem("inline") }
                </Drawer>

                <Header className={styles.header}>
                    <div>
                        <img className={styles.logo} src={"/logo.png"} alt={"universitas muhammadiyah makassar logo"} />
                        <span  className={styles.logoText} >
                            <strong>LEMBAGA KEMAHASISWAAN <br/> UNIVERSITAS MUHAMMADIYAH <br/> MAKASSAR</strong>
                        </span>
                    </div>
                    { menuItem("horizontal") }
                    <Button
                        className={styles.buttonMenu}
                        shape={"circle"}
                        type={"primary"}
                        ghost={true}
                        onClick={() => setVisible(true)}
                    >
                        <MenuOutlined />
                    </Button>
                </Header>

                <Header className={styles.header2}>
                    <Menu selectable={false} className={styles.menu2} theme="light" mode="horizontal">
                        <Menu.Item key="0">
                            <Link className={styles.header2Link} to={"/"}>
                                <strong>Home</strong>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="1">
                            <Link className={styles.header2Link} to={"/berita"}>
                                <strong>Berita</strong>
                            </Link>
                        </Menu.Item>
                        {/*<Menu.Item key="2">
                            <Link className={styles.header2Link} to={"/beasiswa"}>
                                <strong>Beasiswa</strong>
                            </Link>
                        </Menu.Item>*/}
                        <Menu.Item key="3">
                            <Link className={styles.header2Link} to={"/organisasi"}>
                                <strong>Organisasi</strong>
                            </Link>
                        </Menu.Item>
                      {/*  <Menu.Item key="4">
                            <strong>Prestasi</strong>
                        </Menu.Item>*/}
                        <Menu.Item key="5">
                            <Link className={styles.header2Link} to={"/kegiatan-muhammadiyah"}>
                                <strong>Kalender Kemahasiswaan</strong>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="6">
                            <Link className={styles.header2Link} to={"/cerita-inspiratif"}>
                                <strong>Cerita Inspiratif</strong>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="7">
                            <Link className={styles.header2Link} to={"/download"}>
                                <strong>Download</strong>
                            </Link>
                        </Menu.Item>
                    </Menu>
                </Header>
            </Layout>
        </>
    )
}

export default FrontHeader;