import React, {useEffect, useState} from "react";
import styles from "./styles.module.css";
import {Col, Row, Space} from "antd";
import {
    PhoneOutlined,
    MailOutlined,
    EnvironmentOutlined,
    FacebookOutlined,
    InstagramOutlined,
    YoutubeOutlined,
    TeamOutlined
}
from "@ant-design/icons";

const Footer : React.FC = () => {

    const [totalVisitor, setTotalVisitor] = useState<number>(0);

    useEffect( () => {

        async function getTotalVisitor() {

            let url      = `${process.env.REACT_APP_API}/visitor`;
            let response = await fetch( url, {method : "GET"})
            let result   = await response.json();

            setTotalVisitor(result.data[0]?.total || 0)
        }

        getTotalVisitor()

    },[]);

    return (
        <section className={styles.footer}>
            <Row gutter={[10, 10]}>
                <Col style={{width : "100%"}} md={8} sm={24}>
                    <img alt={"unismuh"} style={{width : 150}} src={"/images/logo-footer.png"} />
                    <br/>
                    <br/>
                    <p style={{color : "#ddd"}}>
                        <PhoneOutlined />&nbsp;&nbsp; +62 (411) 866 972
                    </p>
                    <p style={{color : "#ddd"}}>
                        <MailOutlined />&nbsp;&nbsp;  info@unismuh.ac.id
                    </p>
                    <p style={{color : "#ddd"}}>
                        <EnvironmentOutlined />&nbsp;&nbsp;  Jl. Sultan Alauddin No.259 Makassar, Indonesia
                    </p>
                    <p style={{color : "#ddd"}}>
                        <TeamOutlined />&nbsp;&nbsp; Pengunjung : {totalVisitor}
                    </p>
                </Col>
                <Col md={4} sm={24}>
                    <h3 style={{color: "#fff"}}>Tentang Kami</h3>
                    <br/>
                    <p><a style={{color : "#ddd"}} href={"/sejarah"}>Sejarah</a></p>
                    <p><a style={{color : "#ddd"}} href={"/sejarah"}>Visi & Misi</a></p>
                    <p><a style={{color : "#ddd"}} href={"/sejarah"}>Struktur Organisasi</a></p>
                    <p><a style={{color : "#ddd"}} href={"/sejarah"}>Kebijakan dan Program Strategi</a></p>
                    <p><a style={{color : "#ddd"}} href={"/sejarah"}>Pimpinan</a></p>
                </Col>
                <Col md={4} sm={24}>
                    <h3 style={{color: "#fff"}}>Akses Cepat</h3>
                    <br/>
                    <p><a style={{color : "#ddd"}} href={"/sejarah"}>Berita</a></p>
                    <p><a style={{color : "#ddd"}} href={"/sejarah"}>Artikel</a></p>
                    <p><a style={{color : "#ddd"}} href={"/sejarah"}>Pengumuman</a></p>
                    <p><a style={{color : "#ddd"}} href={"/sejarah"}>Agenda</a></p>
                    <p><a style={{color : "#ddd"}} href={"/sejarah"}>Galeri</a></p>
                    <p><a style={{color : "#ddd"}} href={"/sejarah"}>Video</a></p>
                </Col>
                <Col md={8} sm={24}>
                    <h3 style={{color: "#fff"}}><strong>Links</strong></h3>
                    <br/>
                    <p><a style={{color : "#ddd"}} href={"https://www.kemdikbud.go.id/ "}>Kemendikbud</a></p>
                    <p><a style={{color : "#ddd"}} href={"https://bumn.go.id/"}>BUMN</a></p>
                    <p><a style={{color : "#ddd"}} href={"https://kemenag.go.id/"}>Kemenag</a></p>
                    <p><a style={{color : "#ddd"}} href={"https://pusatprestasinasional.kemdikbud.go.id/"}>Pusat Prestasi Nasional</a></p>
                    <Space size={"large"} style={{marginTop : 10}}>
                        <a style={{color : "#ddd"}} href={"/sejarah"}>
                            <FacebookOutlined style={{fontSize: 35, color : "#fff"}} />
                        </a>
                        <a style={{color : "#ddd"}} href={"/sejarah"}>
                            <YoutubeOutlined style={{fontSize: 35, color : "#fff"}} />
                        </a>
                        <a style={{color : "#ddd"}} href={"/sejarah"}>
                            <InstagramOutlined style={{fontSize: 35, color : "#fff"}} />
                        </a>
                    </Space>
                </Col>
            </Row>
            <br/>
            <div style={{borderTop : "1px solid #e2e8f0", padding : "10px"}}/>
            <div style={{color : "#fff", textAlign: "center"}}>
                © 2020 WASDLabs. All rights reserved
            </div>
        </section>
    )
}

export default Footer;