import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import {Spinner} from "components";
import {Auth} from "modules";

// component
import AuthRoute from "./auth.routes";

// front pages
const _Landing              : React.FC = React.lazy(() => import('../pages/_home'));
const _About                : React.FC = React.lazy(() => import('../pages/_about'));
const _News                 : React.FC = React.lazy(() => import('../pages/_news'));
const _InspiringStory       : React.FC = React.lazy(() => import('../pages/_inspiringStory'));
const _MuhammadiyahActivity : React.FC = React.lazy(() => import('../pages/_MuhammadiyahActivity'));
const _Career               : React.FC = React.lazy(() => import('../pages/_career'));
const _Entrepreneurship     : React.FC = React.lazy(() => import('../pages/_entrepreneurship'));
const _Research             : React.FC = React.lazy(() => import('../pages/_research'));
const _Organization         : React.FC = React.lazy(() => import('../pages/_organization'));
const _Scholarship          : React.FC = React.lazy(() => import('../pages/_scholarship'));
const _Competition          : React.FC = React.lazy(() => import('../pages/_competition'));
const _Activity             : React.FC = React.lazy(() => import('../pages/_activity'));
const _Achievement          : React.FC = React.lazy(() => import('../pages/_achievement'));
const _Archive              : React.FC = React.lazy(() => import('../pages/_archive'));

// dahsboard pages
const Login                 : React.FC = React.lazy(() => import('../pages/login'));
const Register              : React.FC = React.lazy(() => import('../pages/register'));
const Dashboard             : React.FC = React.lazy(() => import('../pages/dashboard'));
const Administrator         : React.FC = React.lazy(() => import('../pages/administrator'));
const CollageStudent        : React.FC = React.lazy(() => import('../pages/collageStudent'));
const Teacher               : React.FC = React.lazy(() => import('../pages/teacher'));
const StudentOrganization   : React.FC = React.lazy(() => import('../pages/studentOrganization'));
const SOActivityReport      : React.FC = React.lazy(() => import('../pages/studentOrganizationActivityReport'));
const SOLpj                 : React.FC = React.lazy(() => import('../pages/studentOrganizationLpj'));
const Partner               : React.FC = React.lazy(() => import('../pages/scholarshipPartner'));
const News                  : React.FC = React.lazy(() => import('../pages/news'));
const Career                : React.FC = React.lazy(() => import('../pages/career'));
const InspiringStory        : React.FC = React.lazy(() => import('../pages/inspiringStory'));
const MuhammadiyahActivity  : React.FC = React.lazy(() => import('../pages/muhammadiyahActivity'));
const Entrepreneurship      : React.FC = React.lazy(() => import('../pages/entrepreneurship'));
const Research              : React.FC = React.lazy(() => import('../pages/research'));
const Competition           : React.FC = React.lazy(() => import('../pages/competition'));
const Activity              : React.FC = React.lazy(() => import('../pages/activity'));
const Scholarship           : React.FC = React.lazy(() => import('../pages/scholarship'));
const Achievement           : React.FC = React.lazy(() => import('../pages/achievement'));
const Proposal              : React.FC = React.lazy(() => import('../pages/proposal'));
const About                 : React.FC = React.lazy(() => import('../pages/about'));
const Profile                : React.FC = React.lazy(() => import('../pages/profile'));
const Archive               : React.FC = React.lazy(() => import('../pages/archive'));

const Router : React.FC = () => {
    return (
        <Auth.AuthProvider>
            <React.Suspense fallback={<Spinner tip={'Mohon tunggu...'} spinning={true} />}>
                <BrowserRouter>
                    <Switch>
                        <Route exact path={'/'}                                     component={_Landing}              />
                        <Route       path={'/tentang'}                              component={_About}                />
                        <Route       path={'/berita/:id?/:title?'}                  component={_News}                 />
                        <Route       path={'/cerita-inspiratif/:id?/:title?'}       component={_InspiringStory}       />
                        <Route       path={'/kegiatan-muhammadiyah/:id?/:title?'}   component={_MuhammadiyahActivity} />
                        <Route       path={'/pusat-karir/:category/:id?/:title?'}   component={_Career}               />
                        <Route       path={'/kewirausahaan/:id?/:title?'}           component={_Entrepreneurship}     />
                        <Route       path={'/penelitian/:id?/:title?'}              component={_Research}             />
                        <Route       path={'/organisasi/:id?/:title?'}              component={_Organization}         />
                        <Route       path={'/kompetisi/:id?/:title?'}               component={_Competition}          />
                        <Route       path={'/beasiswa'}                             component={_Scholarship}          />
                        <Route       path={'/pengabdian-masyarakat/:id?/:title?'}   component={_Activity}             />
                        <Route       path={'/prestasi/'}                            component={_Achievement}          />
                        <Route       path={'/download/'}                            component={_Archive}              />

                        <Route       path={'/masuk'}                                component={Login}                 />
                        <Route       path={'/daftar'}                               component={Register}              />
                        <AuthRoute   path={'/kelola/dashboard'}                     component={Dashboard}             />
                        <AuthRoute   path={'/kelola/beasiswa'}                      component={Scholarship}           />
                        <AuthRoute   path={'/kelola/kompetisi'}                     component={Competition}           />
                        <AuthRoute   path={'/kelola/aktivitas'}                     component={Activity}              />
                        <AuthRoute   path={'/kelola/arsip'}                         component={Archive}               />
                        <AuthRoute   path={'/kelola/tulisan/berita'}                component={News}                  />
                        <AuthRoute   path={'/kelola/tulisan/cerita-inspiratif'}     component={InspiringStory}        />
                        <AuthRoute   path={'/kelola/tulisan/kegiatan-muhammadiyah'} component={MuhammadiyahActivity}  />
                        <AuthRoute   path={'/kelola/tulisan/kewirausahaan'}         component={Entrepreneurship}      />
                        <AuthRoute   path={'/kelola/tulisan/pusat-karir'}           component={Career}                />
                        <AuthRoute   path={'/kelola/pengguna/admin'}                component={Administrator}         />
                        <AuthRoute   path={'/kelola/pengguna/mahasiswa'}            component={CollageStudent}        />
                        <AuthRoute   path={'/kelola/pengguna/dosen'}                component={Teacher}               />
                        <AuthRoute   path={'/kelola/pengguna/organisasi-mahasiswa'} component={StudentOrganization}   />
                        <AuthRoute   path={'/kelola/pengguna/mitra-beasiswa'}       component={Partner}               />
                        <AuthRoute   path={'/kelola/penelitian'}                    component={Research}              />
                        <AuthRoute   path={'/kelola/prestasi'}                      component={Achievement}           />
                        <AuthRoute   path={'/kelola/proposal'}                      component={Proposal}              />
                        <AuthRoute   path={'/kelola/pengaturan/tentang'}            component={About}                 />
                        <AuthRoute   path={'/kelola/pengaturan/profil'}              component={Profile}                />

                        <AuthRoute   path={'/kelola/laporan-organisasi-mahasiswa/laporan-kegiatan'}           component={SOActivityReport}  />
                        <AuthRoute   path={'/kelola/laporan-organisasi-mahasiswa/laporan-pertanggungjawaban'} component={SOLpj}  />
                    </Switch>
                </BrowserRouter>
            </React.Suspense>
        </Auth.AuthProvider>
    )
};

export default Router;
