import React, {useState, useEffect} from 'react';
import {Spinner} from "components";
import {uuid} from "utils";
// import {message} from "antd";

// We define our type for the context properties right here
type ContextProps = {
    authenticated : boolean,
    user          : any,
    token         : string
};

export const Auth = React.createContext<ContextProps>({
    authenticated : false,
    user          : {},
    token         : ""
});

interface ProviderProps {

    children : React.ReactChild
}

// const userLevel = [1, 2, 3, 4, 5];

export const AuthProvider : React.FC<ProviderProps> = ({children}) => {

    const [authenticated, setAuthenticated] = useState<boolean>(true);
    const [user, setUser]                   = useState<object>({});
    const [token, setToken]                 = useState<string>("");
    const [loading, setLoading]             = useState<boolean>(false);

    useEffect(() => {

        setLoading(true);

        const events = new EventSource(process.env.REACT_APP_API + '/auth/state?uuid=' + uuid());

        events.onopen = () => {

            // message.info("sambungan server terhubung.")
        }

        events.onmessage = (event) => {

            const parsedData = JSON.parse(event.data);

            setUser(parsedData?.user?.user || {}) ;

            setToken(parsedData.user.token);

            setAuthenticated(parsedData.user.isAuthenticated)

            setLoading(false);
        };

        // events.onerror = () => {
        //
        //     message.info("Sambungan server terputus. menghubungkan kembali...")
        //
        //     /*setLoading(true);*/
        // }

    }, []);

    return (
        <Auth.Provider
            value={{ authenticated, user, token}}
        >
            {
                loading
                    ? <Spinner spinning={true} tip={'Mencoba terhubung kerserver...'} />
                    : children
            }
        </Auth.Provider>
    )
};
