import React from 'react';
import {Spin} from 'antd';
import styles from "./spin.module.css";

const Spinner: React.FC<Props> = ({spinning, tip}) => {

    return (
        <div className={styles.div}>
            <Spin
                className={styles.spin}
                size={"large"}
                spinning={spinning}
                tip={tip}
                /*indicator={
                    <img
                        style={{
                            width: 100,
                            height: 100,
                            marginBottom : 10,
                            animation : '2s linear 0s infinite reverse forwards running fade-c6f2b0aa-4292-4473-89d9-33f9a542e32e;'
                        }}
                        src={"/logo.png"}
                    />
                }*/
            />
        </div>
    )
};

interface Props {
    tip: string,
    spinning: boolean
}

export default Spinner;
