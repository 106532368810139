import React, {useContext} from 'react';
import {Layout, Avatar, Popover, Tag, Button} from 'antd';
import {Auth} from "modules";
import {CaretDownOutlined, LogoutOutlined} from "@ant-design/icons";

import styles from './header.module.css'

import PooperContent from "./pooper.header";
import {uuid} from "utils";

const Header: React.FC = () => {

    const {user} = useContext(Auth.Auth);

    const signOut = async (): Promise<void> => {
        try {

            let myHeaders = new Headers();

            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            let urlencoded = new URLSearchParams();
            urlencoded.append("uuid", uuid());

            await fetch(process.env.REACT_APP_API + "/auth/logout", {
                method : 'POST',
                headers: myHeaders,
                body: urlencoded,
            });

            window.location.reload();

        } catch (e) {

            console.log(e);
        }
    };


    return (
        <Layout.Header className={styles.header}>
            <div className={styles.layoutHeader}>
                <ul>
                    <li className={styles.inline}>
                        <Tag
                            color="blue"
                            style={{
                                paddingRight: 20,
                                borderRadius: 20,
                                paddingLeft : 1,
                            }}
                        >
                            <Avatar
                                src={JSON.parse(user?.photoURL || "[{}]")[0]?.response?.url || 'https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png'}
                                size={'large'}
                            />
                            <strong style={{marginLeft: 10}}>{user.displayName?.slice(0, 20)}</strong>
                        </Tag>
                    </li>
                    <li className={styles.inline}>
                        <Popover
                            placement="bottomRight"
                            trigger="click"
                            title={null}
                            content={
                                <PooperContent
                                    displayName={user?.displayName}
                                    email={user?.email}
                                    photoURL={JSON.parse(user?.photoURL || "[{}]")[0]?.response?.url || 'https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png'}
                                    signOut={signOut}
                                />
                            }
                        >
                            <Button size={"large"} shape={"circle"} type={"primary"} ghost={true}>
                                <CaretDownOutlined />
                            </Button>
                        </Popover>
                    </li>
                    <li className={styles.inline}>
                        <Button onClick={signOut} style={{marginLeft: 6}} size={"large"} shape={"circle"} type={"danger"} ghost={true}>
                            <LogoutOutlined />
                        </Button>
                    </li>
                </ul>
            </div>
        </Layout.Header>
    )
};

export default React.memo(Header);
