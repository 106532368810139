import React, {useEffect, useState, useContext} from "react";
import {Link} from "react-router-dom";
import {Layout, Menu, Button} from 'antd';
import {
    PicLeftOutlined,
    PicRightOutlined,
    SettingFilled,
    PieChartOutlined,
    SecurityScanOutlined,
    EditOutlined,
    TeamOutlined,
    NotificationOutlined,
    CrownOutlined,
    SnippetsOutlined,
    ApartmentOutlined,
    FormOutlined,
    InfoCircleOutlined,
    FileSyncOutlined
} from '@ant-design/icons';
import {Auth} from "modules";

// style
import styles from './sider.module.css'

const Sider: React.FC = () => {

    const [rootSubMenuKeys, ]               = useState<string[]>(['pengaturan', 'tulisan', 'pengguna', 'laporan-organisasi-mahasiswa']);
    const [openKeys, setOpenKeys]           = useState<string[]>([]);
    const [selectedKeys, setSelectedKeys]   = useState<string>('');
    const [collapse, setCollapse]           = useState<boolean>(false);

    const {user} = useContext(Auth.Auth);

    const onBreakpoint = (broken: boolean): void => {

        setCollapse(broken)
    };

    const onTrigger = (): void => {

        setCollapse(prevState => !prevState)
    };

    const onOpenChange = (keys : string[]) : void => {

        const latestOpenKey : string | undefined = keys.find(key => openKeys.indexOf(key) === -1);

        if (latestOpenKey){

            if (rootSubMenuKeys.indexOf(latestOpenKey) === -1) {

                setOpenKeys(openKeys)
            }else{

                setOpenKeys( [latestOpenKey])
            }
        }else{

            setOpenKeys( [])
        }
    };

    useEffect(() => {

        let pathname : string = window.location.pathname;

        setSelectedKeys(pathname);

        setOpenKeys([pathname.split("/")[2]]);

    }, []);

    useEffect(() => {

        let body = document.querySelector(".ant-layout-content") as HTMLBodyElement;

        body.addEventListener("touchstart", () => {
           setCollapse(true);
        })

    }, []);

    return (
        <div>

            <div className={styles.buttonCollapse}>
                <Button shape={'circle'} size={'large'} onClick={onTrigger}>
                    {!collapse ? <PicLeftOutlined/> : <PicRightOutlined/>}
                </Button>
            </div>

            <Layout.Sider
                collapsed={collapse}
                breakpoint={"lg"}
                collapsedWidth={0}
                className={styles.sider}
                width={'250'}
                trigger={null}
                onBreakpoint={onBreakpoint}
                collapsible={true}
            >

                <div className={styles.logo} >
                   <Link to={"/"}>
                       <img width={100} src={"/logo.png"} alt={"logo"}/>
                   </Link>
                </div>
                    <Menu
                        theme="light"
                        mode="inline"
                        selectedKeys={[selectedKeys]}
                        openKeys={openKeys}
                        onOpenChange={onOpenChange}
                        style={{background: "transparent"}}
                    >

                        {/*<Menu.Item>*/}
                        {/*    <h4 style={{color : "#fff"}}>Menu utama</h4>*/}
                        {/*</Menu.Item>*/}

                        <Menu.Item key="/kelola/dashboard">
                            <Link to={'/kelola/dashboard'}>
                                <PieChartOutlined style={{fontSize: 18}} />
                                <span className="nav-text">Dashboard</span>
                            </Link>
                        </Menu.Item>

                        {
                            [5, 1].includes(user.userLevel) ? (
                                <Menu.Item key="/kelola/penelitian">
                                    <Link to={'/kelola/penelitian'}>
                                        <SecurityScanOutlined style={{fontSize: 18}} />
                                        <span className="nav-text">Penelitian</span>
                                    </Link>
                                </Menu.Item>
                            ) : null
                        }

                        {
                            [5, 1].includes(user.userLevel) ? (
                                <Menu.Item key="/kelola/prestasi">
                                    <Link to={'/kelola/prestasi'}>
                                        <CrownOutlined style={{fontSize: 18}} />
                                        <span className="nav-text">Prestasi</span>
                                    </Link>
                                </Menu.Item>
                            ) : null
                        }

                        {
                            [5, 4, 1].includes(user.userLevel) ? (
                                <Menu.Item key="/kelola/beasiswa">
                                    <Link to={'/kelola/beasiswa'}>
                                        <NotificationOutlined style={{fontSize: 18}} />
                                        <span className="nav-text">Beasiswa</span>
                                    </Link>
                                </Menu.Item>
                            ) : null
                        }

                        {
                            [5, 1, 2].includes(user.userLevel) ? (
                                <Menu.Item key="/kelola/proposal">
                                    <Link to={'/kelola/proposal'}>
                                        <FormOutlined style={{fontSize: 18}} />
                                        <span className="nav-text">Proposal</span>
                                    </Link>
                                </Menu.Item>
                            ) : null
                        }

                        {
                            user.userLevel === 5 ? (
                                <Menu.Item key="/kelola/kompetisi">
                                    <Link to={'/kelola/kompetisi'}>
                                        <ApartmentOutlined style={{fontSize: 18}} />
                                        <span className="nav-text">Kompetisi</span>
                                    </Link>
                                </Menu.Item>
                            ) : null
                        }

                        {
                            [5, 3].includes(user.userLevel) ? (
                                <Menu.Item key="/kelola/aktivitas">
                                    <Link to={'/kelola/aktivitas'}>
                                        <SnippetsOutlined style={{fontSize: 18}} />
                                        <span className="nav-text">Pengabdian</span>
                                    </Link>
                                </Menu.Item>
                            ) : null
                        }

                        {
                            user.userLevel === 5 ? (
                                <Menu.Item key="/kelola/arsip">
                                    <Link to={'/kelola/arsip'}>
                                        <FileSyncOutlined style={{fontSize: 18}} />
                                        <span className="nav-text">Arsip</span>
                                    </Link>
                                </Menu.Item>
                            ) : null
                        }

                        {
                            [5, 3].includes(user.userLevel) ? (
                                <Menu.SubMenu
                                    key={'laporan-organisasi-mahasiswa'}
                                    title={
                                        <span>
                                           <InfoCircleOutlined style={{fontSize: 18}} />
                                          <span>Laporan Organisasi</span>
                                        </span>
                                    }
                                >
                                    <Menu.Item key="/kelola/laporan-organisasi-mahasiswa/laporan-kegiatan">
                                        <Link to={'/kelola/laporan-organisasi-mahasiswa/laporan-kegiatan'}>
                                            <span className="nav-text">Laporan Kegiatan</span>
                                        </Link>
                                    </Menu.Item>

                                    <Menu.Item key="/kelola/laporan-organisasi-mahasiswa/laporan-pertanggungjawaban">
                                        <Link to={'/kelola/laporan-organisasi-mahasiswa/laporan-pertanggungjawaban'}>
                                            <span className="nav-text">LPJ</span>
                                        </Link>
                                    </Menu.Item>
                                </Menu.SubMenu>
                            ) : null
                        }

                        {
                            user.userLevel === 5 ? (
                                <Menu.SubMenu
                                    key={'pengguna'}
                                    title={
                                        <span>
                                           <TeamOutlined style={{fontSize: 18}} />
                                          <span>Pengguna</span>
                                        </span>
                                    }
                                >
                                    {
                                        user.id === 1 && (
                                            <Menu.Item key="/kelola/pengguna/admin">
                                                <Link to={'/kelola/pengguna/admin'}>
                                                    <span className="nav-text">Admin</span>
                                                </Link>
                                            </Menu.Item>
                                        )
                                    }

                                    <Menu.Item key="/kelola/pengguna/mahasiswa">
                                        <Link to={'/kelola/pengguna/mahasiswa'}>
                                            <span className="nav-text">Mahasiswa</span>
                                        </Link>
                                    </Menu.Item>

                                    <Menu.Item key="/kelola/pengguna/dosen">
                                        <Link to={'/kelola/pengguna/dosen'}>
                                            <span className="nav-text">Dosen</span>
                                        </Link>
                                    </Menu.Item>

                                    <Menu.Item key="/kelola/pengguna/organisasi-mahasiswa">
                                        <Link to={'/kelola/pengguna/organisasi-mahasiswa'}>
                                            <span className="nav-text">Organisasi mahasiswa</span>
                                        </Link>
                                    </Menu.Item>

                                    <Menu.Item key="/kelola/pengguna/mitra-beasiswa">
                                        <Link to={'/kelola/pengguna/mitra-beasiswa'}>
                                            <span className="nav-text">Mitra beasiswa</span>
                                        </Link>
                                    </Menu.Item>
                                </Menu.SubMenu>
                            ) : null
                        }


                        {
                            user.userLevel === 5 ? (
                                <Menu.SubMenu
                                    key={'tulisan'}
                                    title={
                                        <span>
                                         <EditOutlined style={{fontSize: 18}} />
                                          <span>Tulisan</span>
                                        </span>
                                    }
                                >
                                    <Menu.Item key="/kelola/tulisan/berita">
                                        <Link to={'/kelola/tulisan/berita'}>
                                            <span className="nav-text">Berita</span>
                                        </Link>
                                    </Menu.Item>

                                    <Menu.Item key="/kelola/tulisan/cerita-inspiratif">
                                        <Link to={'/kelola/tulisan/cerita-inspiratif'}>
                                            <span className="nav-text">Cerita inspiratif</span>
                                        </Link>
                                    </Menu.Item>

                                    <Menu.Item key="/kelola/tulisan/kegiatan-muhammadiyah">
                                        <Link to={'/kelola/tulisan/kegiatan-muhammadiyah'}>
                                            <span className="nav-text">Kalender Kemahasiswaan</span>
                                        </Link>
                                    </Menu.Item>

                                    <Menu.Item key="/kelola/tulisan/Kewirausahaan">
                                        <Link to={'/kelola/tulisan/Kewirausahaan'}>
                                            <span className="nav-text">Inkubator Kewirausahaan</span>
                                        </Link>
                                    </Menu.Item>

                                    <Menu.Item key="/kelola/tulisan/pusat-karir">
                                        <Link to={'/kelola/tulisan/pusat-karir'}>
                                            <span className="nav-text">Pusat Karir</span>
                                        </Link>
                                    </Menu.Item>
                                </Menu.SubMenu>
                            ) : null
                        }

                        <Menu.SubMenu
                            key={'pengaturan'}
                            title={
                                <span>
                                  <SettingFilled style={{fontSize: 18}} />
                                  <span>Pengaturan</span>
                                </span>
                            }
                        >
                            <Menu.Item key="/kelola/pengaturan/profil">
                                <Link to={'/kelola/pengaturan/profil'}>
                                    <span className="nav-text">Profil</span>
                                </Link>
                            </Menu.Item>

                            {
                                user.userLevel === 5 ? (
                                    <Menu.Item key="/kelola/pengaturan/tentang">
                                        <Link to={'/kelola/pengaturan/tentang'}>
                                            <span className="nav-text">Tentang</span>
                                        </Link>
                                    </Menu.Item>
                                ) : null
                            }

                        </Menu.SubMenu>

                    </Menu>
            </Layout.Sider>
        </div>
    )
};

export default Sider;
