
// convert snapshot to array
export const parseSnapshot = (snapshot : any) : any[] => {

    let arr : any[] = [];

    snapshot.forEach((doc : any) => {

        if (doc.data().timestamp) {

            arr.push({...doc.data(), key : doc.id, timestamp : doc.data().timestamp?.toDate()})
        } else if (doc.data().datetime) {

            arr.push({...doc.data(), key : doc.id, datetime : doc.data().datetime?.toDate()})
        } else {

            arr.push({...doc.data(), key : doc.id})
        }
    });

    return arr;
};

// delay
export const sleep = (time : number) : Promise<string> => {
    return new Promise(resolve => {
        setTimeout(() => {
            return resolve();
        }, time)
    })
};

// paginate array
export const paginate = (array : any[], page_size : number, page_number : number) : any[] => {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.slice((page_number - 1) * page_size, page_number * page_size);
};

// genrateId
export const uuid = () : string => {

    let currentUUID = localStorage.getItem("uuid");

    if (currentUUID) {

        return currentUUID

    } else {

        let dt = new Date().getTime();

        let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            let r = (dt + Math.random()*16)%16 | 0;
            dt = Math.floor(dt/16);
            return (c === 'x' ? r :((r&0x3)|0x8)).toString(16);
        });

        localStorage.setItem("uuid", uuid);

        return uuid;
    }
}

// group by
export const groupBy = (data : [], by : string, field : string[]) : any[] => {

    let result : any = {};

    for (let i in data) {

        if (!data.hasOwnProperty(i)) continue;

        if (result[data[i][by]] === undefined) {

            result[data[i][by]] = data[i];

            for (let f in field) {
                result[data[i][by]] = {
                    ...result[data[i][by]],
                    [field[f]]: [data[i][field[f]]]
                }
            }

        } else {

            for (let f in field) {

                let record = result[data[i][by]];

                record[field[f]].push(data[i][field[f]]);

                record[field[f]] = Array.from(new Set(record[field[f]]))
            }
        }
    }

    return Object.values(result);
};